import { createSlice } from '@reduxjs/toolkit';
import { authApi } from '@services';
import { permissionApi, PermissionsEnum } from '@services/permissions.api';
const initialState = [];
const slice = createSlice({
    name: 'permission',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addMatcher(permissionApi.endpoints.getPermissions.matchFulfilled, (state, { payload }) => {
            return payload;
        });
        // Logout route handles clearing the slice state
        builder.addMatcher(authApi.endpoints.logout.matchFulfilled, () => (Object.assign({}, initialState)));
    },
});
export const permissionReducer = slice.reducer;
export const canManageFarmerSelector = (state) => Boolean(state.permissions.length > 0
    ? state.permissions.find((permission) => permission === PermissionsEnum.PP_CAN_SEE_ALL_PARTNER_FARMERS ||
        permission === PermissionsEnum.PP_CAN_SEE_ASSIGNED_PARTNER_FARMERS ||
        permission === PermissionsEnum.PP_CAN_SEE_ASSOCIATE_FARMERS ||
        permission === PermissionsEnum.PP_CAN_SEE_ASSOCIATE_ASSIGNED_FARMERS)
    : false);
export const canInviteFarmer = (state) => Boolean(state.permissions.length > 0
    ? state.permissions.find((permission) => permission === PermissionsEnum.PP_CAN_INVITE_PARTNER_FARMERS)
    : false);
export const canDeleteAllFarmersSelector = (state) => Boolean(state.permissions.length > 0
    ? state.permissions.find((permission) => permission === PermissionsEnum.PP_CAN_DELETE_ALL_FARMERS)
    : false);
export const canEditFarmerSelector = (state) => Boolean(state.permissions.length > 0
    ? state.permissions.find((permission) => permission === PermissionsEnum.PP_CAN_EDIT_FARMER)
    : false);
export const canEditAllFarmersSelector = (state) => Boolean(state.permissions.length > 0
    ? state.permissions.find((permission) => permission === PermissionsEnum.PP_CAN_EDIT_ALL_FARMERS)
    : false);
export const canSeeAllPartnerFarmersSelector = (state) => Boolean(state.permissions.length > 0
    ? state.permissions.find((permission) => permission === PermissionsEnum.PP_CAN_SEE_ALL_PARTNER_FARMERS)
    : false);
export const canSeeAssignedPartnerFarmersSelector = (state) => Boolean(state.permissions.length > 0
    ? state.permissions.find((permission) => permission === PermissionsEnum.PP_CAN_SEE_ASSIGNED_PARTNER_FARMERS)
    : false);
export const canAssignPartnerMemberToFarmerSelector = (state) => Boolean(state.permissions.length > 0
    ? state.permissions.find((permission) => permission === PermissionsEnum.PP_CAN_ASSIGN_PARTNER_INVITATION)
    : false);
export const canAssignAssociateToFarmerSelector = (state) => Boolean(state.permissions.length > 0
    ? state.permissions.find((permission) => permission === PermissionsEnum.PP_CAN_ASSIGN_ASSOCIATE_TO_FARMER)
    : false);
export const canSeePartnerMembersSelector = (state) => Boolean(state.permissions.length > 0
    ? state.permissions.find((permission) => permission === PermissionsEnum.PP_CAN_SEE_PARTNER_MEMBERS)
    : false);
export const canEditPartnerMembersSelector = (state) => Boolean(state.permissions.length > 0
    ? state.permissions.find((permission) => permission === PermissionsEnum.PP_CAN_EDIT_ALL_MEMBERS)
    : false);
export const canDeletePartnerMembersSelector = (state) => Boolean(state.permissions.length > 0
    ? state.permissions.find((permission) => permission === PermissionsEnum.PP_CAN_DELETE_ALL_MEMBERS)
    : false);
export const canSeeAssociateFarmersSelector = (state) => Boolean(state.permissions.length > 0
    ? state.permissions.find((permission) => permission === PermissionsEnum.PP_CAN_SEE_ASSOCIATE_FARMERS)
    : false);
export const canSeeAssociateAssignedFarmersSelector = (state) => Boolean(state.permissions.length > 0
    ? state.permissions.find((permission) => permission === PermissionsEnum.PP_CAN_SEE_ASSOCIATE_ASSIGNED_FARMERS)
    : false);
export const canSeeAssociateMembersSelector = (state) => Boolean(state.permissions.length > 0
    ? state.permissions.find((permission) => permission === PermissionsEnum.PP_CAN_SEE_ASSOCIATE_MEMBERS)
    : false);
export const canSeeDashboardSelector = (state) => Boolean(state.permissions.length > 0
    ? state.permissions.find((permission) => permission === PermissionsEnum.PP_CAN_SEE_DASHBOARD)
    : false);
export const canBeAssignedByPartnerSelector = (state) => Boolean(state.permissions.length > 0
    ? state.permissions.find((permission) => permission === PermissionsEnum.PP_CAN_BE_ASSIGNED_BY_PARTNER)
    : false);
export const canBeAssignedByAssociateSelector = (state) => Boolean(state.permissions.length > 0
    ? state.permissions.find((permission) => permission === PermissionsEnum.PP_CAN_BE_ASSIGNED_BY_ASSOCIATE)
    : false);
export const canAccessPlatformAsPartnerSelector = (state) => Boolean(state.permissions.length > 0
    ? state.permissions.find((permission) => permission === PermissionsEnum.PP_ACCESS_TO_PLATFORM_AS_PARTNER)
    : false);
export const canAccessPlatformAsAssociateSelector = (state) => Boolean(state.permissions.length > 0
    ? state.permissions.find((permission) => permission === PermissionsEnum.PP_ACCESS_TO_PLATFORM_AS_ASSOCIATE)
    : false);
export const canVisitAssociateFarmSelector = (state) => Boolean(state.permissions.length > 0
    ? state.permissions.find((permission) => permission === PermissionsEnum.FP_CAN_VISIT_ASSOCIATE_FARM)
    : false);
export const canVisitAssignedAssociateFarmSelector = (state) => Boolean(state.permissions.length > 0
    ? state.permissions.find((permission) => permission === PermissionsEnum.FP_CAN_VISIT_ASSIGNED_ASSOCIATE_FARM)
    : false);
export const canVisitPartnerFarmSelector = (state) => Boolean(state.permissions.length > 0
    ? state.permissions.find((permission) => permission === PermissionsEnum.FP_CAN_VISIT_PARTNER_FARM)
    : false);
export const canVisitAssignedPartnerFarmSelector = (state) => Boolean(state.permissions.length > 0
    ? state.permissions.find((permission) => permission === PermissionsEnum.FP_CAN_VISIT_ASSIGNED_PARTNER_FARM)
    : false);
export const canSeeAlarmingDataSelector = (state) => Boolean(state.permissions.length > 0
    ? state.permissions.find((permission) => permission === PermissionsEnum.PP_CAN_SEE_ALARMING_DATA)
    : false);
export const canSeeAssignedAlarmingDataSelector = (state) => Boolean(state.permissions.length > 0
    ? state.permissions.find((permission) => permission === PermissionsEnum.PP_CAN_SEE_ASSIGNED_ALARMING_DATA)
    : false);
export const canSeePartnerAlarmingDataSelector = (state) => Boolean(state.permissions.length > 0
    ? state.permissions.find((permission) => permission === PermissionsEnum.PP_CAN_SEE_PARTNER_ALARMING_DATA)
    : false);
export const canSeeAssignedPartnerAlarmingDataSelector = (state) => Boolean(state.permissions.length > 0
    ? state.permissions.find((permission) => permission === PermissionsEnum.PP_CAN_SEE_ASSIGNED_PARTNER_ALARMING_DATA)
    : false);
export const canApplyDeferredPaymentSelector = (state) => Boolean(state.permissions.length > 0
    ? state.permissions.find((permission) => permission === PermissionsEnum.PP_CAN_ASSOCIATE_DEFER_PAYMENT)
    : false);
export const canApplyDeferredPaymentAssignedFarmersSelector = (state) => Boolean(state.permissions.length > 0
    ? state.permissions.find((permission) => permission === PermissionsEnum.PP_CAN_ASSOCIATE_DEFER_PAYMENT_ASSIGNED_FARMERS)
    : false);
export const canEditAllFarmSeasonSelector = (state) => {
    return Boolean(state.permissions.length > 0
        ? state.permissions.find((permission) => permission === PermissionsEnum.FP_CAN_EDIT_ALL_FARM_SEASON)
        : false);
};
export const canFinalizeAllFarmSeasonSelector = (state) => {
    const permissions = [
        PermissionsEnum.FP_CAN_FINALIZE_ALL_FARM_SEASON,
        PermissionsEnum.FP_CAN_FINALIZE_ASSIGNED_ASSOCIATE_FARM_SEASON,
        PermissionsEnum.FP_CAN_FINALIZE_PARTNER_FARM_SEASON,
        PermissionsEnum.FP_CAN_FINALIZE_ASSIGNED_PARTNER_FARM_SEASON,
    ];
    return Boolean(state.permissions.length > 0 ? state.permissions.some((permission) => permissions.includes(permission)) : false);
};
export const canValidateResultsSelector = (state) => {
    const permissions = [
        PermissionsEnum.PP_CAN_VALIDATE_ALL_RESULTS,
        PermissionsEnum.PP_CAN_VALIDATE_ASSIGNED_FARMERS_RESULTS,
        PermissionsEnum.PP_CAN_VALIDATE_PARTNER_RESULTS,
        PermissionsEnum.PP_CAN_VALIDATE_ASSIGNED_PARTNER_RESULTS,
    ];
    return Boolean(state.permissions.length > 0 ? state.permissions.some((permission) => permissions.includes(permission)) : false);
};
export const canSeeResultsSelector = (state) => {
    const permissions = [
        PermissionsEnum.FP_CAN_SEE_ALL_RESULT,
        PermissionsEnum.FP_CAN_SEE_ASSIGNED_ASSOCIATE_RESULT,
        PermissionsEnum.FP_CAN_SEE_ASSIGNED_PARTNER_RESULT,
        PermissionsEnum.FP_CAN_SEE_UNPUBLISHED_RESULT,
    ];
    return Boolean(state.permissions.length > 0 ? state.permissions.some((permission) => permissions.includes(permission)) : false);
};
