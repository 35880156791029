import { canAccessPlatformAsAssociateSelector, canAccessPlatformAsPartnerSelector, canApplyDeferredPaymentAssignedFarmersSelector, canApplyDeferredPaymentSelector, canAssignAssociateToFarmerSelector, canAssignPartnerMemberToFarmerSelector, canBeAssignedByAssociateSelector, canBeAssignedByPartnerSelector, canDeleteAllFarmersSelector, canDeletePartnerMembersSelector, canEditAllFarmSeasonSelector, canEditAllFarmersSelector, canEditFarmerSelector, canEditPartnerMembersSelector, canFinalizeAllFarmSeasonSelector, canInviteFarmer, canManageFarmerSelector, canSeeAlarmingDataSelector, canSeeAllPartnerFarmersSelector, canSeeAssignedAlarmingDataSelector, canSeeAssignedPartnerAlarmingDataSelector, canSeeAssignedPartnerFarmersSelector, canSeeAssociateAssignedFarmersSelector, canSeeAssociateFarmersSelector, canSeeAssociateMembersSelector, canSeeDashboardSelector, canSeePartnerAlarmingDataSelector, canSeePartnerMembersSelector, canSeeResultsSelector, canValidateResultsSelector, canVisitAssignedAssociateFarmSelector, canVisitAssignedPartnerFarmSelector, canVisitAssociateFarmSelector, canVisitPartnerFarmSelector, } from '@features/permission/permissionSlice';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
export const usePermission = () => {
    const canManageFarmer = useSelector(canManageFarmerSelector);
    const canDeleteAllFarmers = useSelector(canDeleteAllFarmersSelector);
    const canEditFarmer = useSelector(canEditFarmerSelector);
    const canEditAllFarmers = useSelector(canEditAllFarmersSelector);
    const canSeeAllPartnerFarmers = useSelector(canSeeAllPartnerFarmersSelector);
    const canSeeAssignedPartnerFarmers = useSelector(canSeeAssignedPartnerFarmersSelector);
    const canInviteFarmers = useSelector(canInviteFarmer);
    const canAssignPartnerMemberToFarmer = useSelector(canAssignPartnerMemberToFarmerSelector);
    const canAssignAssociateToFarmer = useSelector(canAssignAssociateToFarmerSelector);
    const canSeePartnerMembers = useSelector(canSeePartnerMembersSelector);
    const canEditPartnerMembers = useSelector(canEditPartnerMembersSelector);
    const canDeletePartnerMembers = useSelector(canDeletePartnerMembersSelector);
    const canSeeAssociateFarmers = useSelector(canSeeAssociateFarmersSelector);
    const canSeeAssociateAssignedFarmers = useSelector(canSeeAssociateAssignedFarmersSelector);
    const canSeeAssociateMembers = useSelector(canSeeAssociateMembersSelector);
    const canSeeDashboard = useSelector(canSeeDashboardSelector);
    const canBeAssignedByPartner = useSelector(canBeAssignedByPartnerSelector);
    const canBeAssignedByAssociate = useSelector(canBeAssignedByAssociateSelector);
    const canAccessPlatformAsPartner = useSelector(canAccessPlatformAsPartnerSelector);
    const canAccessPlatformAsAssociate = useSelector(canAccessPlatformAsAssociateSelector);
    const canVisitAssociateFarm = useSelector(canVisitAssociateFarmSelector);
    const canVisitAssignedAssociateFarm = useSelector(canVisitAssignedAssociateFarmSelector);
    const canVisitPartnerFarm = useSelector(canVisitPartnerFarmSelector);
    const canVisitAssignedPartnerFarm = useSelector(canVisitAssignedPartnerFarmSelector);
    const canSeeAlarmingData = useSelector(canSeeAlarmingDataSelector);
    const canSeePartnerAlarmingData = useSelector(canSeePartnerAlarmingDataSelector);
    const canSeeAssignedPartnerAlarmingData = useSelector(canSeeAssignedPartnerAlarmingDataSelector);
    const canSeeAssignedAlarmingData = useSelector(canSeeAssignedAlarmingDataSelector);
    const canApplyDeferredPayment = useSelector(canApplyDeferredPaymentSelector);
    const canApplyDeferredPaymentAssignedFarmers = useSelector(canApplyDeferredPaymentAssignedFarmersSelector);
    const canEditAllFarmSeason = useSelector(canEditAllFarmSeasonSelector);
    const canFinalizeFarmSeason = useSelector(canFinalizeAllFarmSeasonSelector);
    const canValidateResults = useSelector(canValidateResultsSelector);
    const canSeeResults = useSelector(canSeeResultsSelector);
    return useMemo(() => ({
        canManageFarmer,
        canDeleteAllFarmers,
        canEditFarmer,
        canEditAllFarmers,
        canSeeAllPartnerFarmers,
        canSeeAssignedPartnerFarmers,
        canInviteFarmers,
        canAssignPartnerMemberToFarmer,
        canSeePartnerMembers,
        canEditPartnerMembers,
        canDeletePartnerMembers,
        canSeeAssociateFarmers,
        canSeeAssociateMembers,
        canSeeDashboard,
        canBeAssignedByPartner,
        canBeAssignedByAssociate,
        canAccessPlatformAsPartner,
        canAccessPlatformAsAssociate,
        canVisitAssociateFarm,
        canVisitAssignedAssociateFarm,
        canVisitPartnerFarm,
        canVisitAssignedPartnerFarm,
        canAssignAssociateToFarmer,
        canSeeAssociateAssignedFarmers,
        canSeeAlarmingData,
        canSeePartnerAlarmingData,
        canSeeAssignedPartnerAlarmingData,
        canSeeAssignedAlarmingData,
        canApplyDeferredPayment,
        canApplyDeferredPaymentAssignedFarmers,
        canEditAllFarmSeason,
        canFinalizeFarmSeason,
        canSeeResults,
        canValidateResults,
    }), [
        canManageFarmer,
        canDeleteAllFarmers,
        canEditFarmer,
        canEditAllFarmers,
        canSeeAllPartnerFarmers,
        canSeeAssignedPartnerFarmers,
        canInviteFarmers,
        canAssignPartnerMemberToFarmer,
        canSeePartnerMembers,
        canEditPartnerMembers,
        canDeletePartnerMembers,
        canSeeAssociateFarmers,
        canSeeAssociateMembers,
        canSeeDashboard,
        canBeAssignedByPartner,
        canBeAssignedByAssociate,
        canAccessPlatformAsPartner,
        canAccessPlatformAsAssociate,
        canVisitAssociateFarm,
        canVisitAssignedAssociateFarm,
        canVisitPartnerFarm,
        canVisitAssignedPartnerFarm,
        canAssignAssociateToFarmer,
        canSeeAssociateAssignedFarmers,
        canSeeAlarmingData,
        canSeeAssignedAlarmingData,
        canApplyDeferredPayment,
        canApplyDeferredPaymentAssignedFarmers,
        canEditAllFarmSeason,
        canSeePartnerAlarmingData,
        canSeeAssignedPartnerAlarmingData,
        canFinalizeFarmSeason,
        canSeeResults,
        canValidateResults,
    ]);
};
