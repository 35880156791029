export const ALARMING_DATA_CHECK_NAMES = {
    YOY_GHG_BALANCE: 'YOY_GHG_BALANCE',
    BENCHMARK_ORGANIC_FERTILIZER: 'BENCHMARK_ORGANIC_FERTILIZER',
    YOY_AGROFORESTRY_TREES_AMOUNT_DECREASE: 'YOY_AGROFORESTRY_TREES_AMOUNT_DECREASE',
    YOY_AGROFORESTRY_TREES_DIAMETER_DECREASE: 'YOY_AGROFORESTRY_TREES_DIAMETER_DECREASE',
    YOY_LIVESTOCK_AMOUNT_CHANGED: 'YOY_LIVESTOCK_AMOUNT_CHANGED',
    YOY_COVER_CROP_FARM_LEVEL: 'YOY_COVER_CROP_FARM_LEVEL',
    BENCHMARK_TILLAGE_STRATEGY_CROP: 'BENCHMARK_TILLAGE_STRATEGY_CROP',
    YOY_FIELD_TILLAGE_INCREASE: 'YOY_FIELD_TILLAGE_INCREASE',
    YOY_FIELDS_AREA_CHANGES: 'YOY_FIELDS_AREA_CHANGES',
    BENCHMARK_UNLIKELY_COMPANION_CROPS: 'BENCHMARK_UNLIKELY_COMPANION_CROPS',
    YOY_UNLIKELY_COVER_CROP_FIELD_LEVEL: 'YOY_UNLIKELY_COVER_CROP_FIELD_LEVEL',
    YOY_FIELD_TILLAGE_DISCREPANCY: 'YOY_FIELD_TILLAGE_DISCREPANCY',
    YOY_ORGANIC_FERTILIZERS_STRATEGY: 'YOY_ORGANIC_FERTILIZERS_STRATEGY',
    BENCHMARK_YIELD_BOUNDARIES: 'BENCHMARK_YIELD_BOUNDARIES',
    BENCHMARK_YIELD_BOUNDARIES_ORG: 'BENCHMARK_YIELD_BOUNDARIES_ORG',
    BENCHMARK_YIELD_BOUNDARIES_NON_ORG: 'BENCHMARK_YIELD_BOUNDARIES_NON_ORG',
    BENCHMARK_MINERAL_FERTILIZER: 'BENCHMARK_MINERAL_FERTILIZER',
    YOY_HISTORICAL_FIELDS: 'YOY_HISTORICAL_FIELDS',
    BENCHMARK_OPERATIONS_SUCCESSIONS_PLOUGH: 'BENCHMARK_OPERATIONS_SUCCESSIONS_PLOUGH',
    BENCHMARK_OPERATIONS_SUCCESSIONS_HARVEST: 'BENCHMARK_OPERATIONS_SUCCESSIONS_HARVEST',
    BENCHMARK_OPERATIONS_SUCCESSIONS_SEEDING_TILLAGE: 'BENCHMARK_OPERATIONS_SUCCESSIONS_SEEDING_TILLAGE',
    BENCHMARK_OPERATIONS_SUCCESSIONS_SAME_MACHINERY: 'BENCHMARK_OPERATIONS_SUCCESSIONS_SAME_MACHINERY',
    BENCHMARK_OPERATIONS_SUCCESSIONS_SEEDING_SOLID_MANURE: 'BENCHMARK_OPERATIONS_SUCCESSIONS_SEEDING_SOLID_MANURE',
    YOY_UNLIKELY_COVER_CROP_FIELD_LEVEL_V3: 'YOY_UNLIKELY_COVER_CROP_FIELD_LEVEL_V3',
    YOY_UNLIKELY_COVER_CROP_FIELD_LEVEL_V3_COVER_CROP_DURATION_TOO_SHORT: 'YOY_UNLIKELY_COVER_CROP_FIELD_LEVEL_V3_COVER_CROP_DURATION_TOO_SHORT',
    YOY_UNLIKELY_COVER_CROP_FIELD_LEVEL_V3_COVER_CROP_SEEDING_BEFORE_PREVIOUS_HARVEST: 'YOY_UNLIKELY_COVER_CROP_FIELD_LEVEL_V3_COVER_CROP_SEEDING_BEFORE_PREVIOUS_HARVEST',
    YOY_UNLIKELY_COVER_CROP_FIELD_LEVEL_V3_COVER_CROP_DESTRUCTION_AFTER_CROP_SEEDING: 'YOY_UNLIKELY_COVER_CROP_FIELD_LEVEL_V3_COVER_CROP_DESTRUCTION_AFTER_CROP_SEEDING',
    YOY_UNLIKELY_COVER_CROP_FIELD_LEVEL_V3_TIME_BETWEEN_PREVIOUS_HARVEST_AND_SEEDING_TOO_LONG: 'YOY_UNLIKELY_COVER_CROP_FIELD_LEVEL_V3_TIME_BETWEEN_PREVIOUS_HARVEST_AND_SEEDING_TOO_LONG',
    YOY_UNLIKELY_COVER_CROP_FIELD_LEVEL_V3_RAPESEED_MISSING_COVER_CROP: 'YOY_UNLIKELY_COVER_CROP_FIELD_LEVEL_V3_RAPESEED_MISSING_COVER_CROP',
    YOY_UNLIKELY_COVER_CROP_FIELD_LEVEL_V3_RAPESEED_PREVIOUS_HARVEST_NOT_EQUAL_COVER_CROP_SEEDING: 'YOY_UNLIKELY_COVER_CROP_FIELD_LEVEL_V3_RAPESEED_PREVIOUS_HARVEST_NOT_EQUAL_COVER_CROP_SEEDING',
    BENCHMARK_UNUSUALY_DOUBLE_CROPS: 'BENCHMARK_UNUSUALY_DOUBLE_CROPS',
    BENCHMARK_UNUSUALY_DOUBLE_CROPS_V3: 'BENCHMARK_UNUSUALY_DOUBLE_CROPS_V3',
    BENCHMARK_AGROFORESTRY_TREES_PER_HA: 'BENCHMARK_AGROFORESTRY_TREES_PER_HA',
};
export const ALARMING_DATA_CHECK_SLUGS = {
    // ----- YOY -----
    [ALARMING_DATA_CHECK_NAMES.YOY_GHG_BALANCE]: 'alarming-data.checks.yoy.ghg-balance',
    [ALARMING_DATA_CHECK_NAMES.YOY_AGROFORESTRY_TREES_AMOUNT_DECREASE]: 'alarming-data.checks.yoy.agroforestry-trees-amount-decrease',
    [ALARMING_DATA_CHECK_NAMES.YOY_AGROFORESTRY_TREES_DIAMETER_DECREASE]: 'alarming-data.checks.yoy.agroforestry-trees-diameter-decrease',
    [ALARMING_DATA_CHECK_NAMES.YOY_LIVESTOCK_AMOUNT_CHANGED]: 'alarming-data.checks.yoy.livestock-amount-changed',
    [ALARMING_DATA_CHECK_NAMES.YOY_COVER_CROP_FARM_LEVEL]: 'alarming-data.checks.yoy.cover-crop-farm-level',
    [ALARMING_DATA_CHECK_NAMES.YOY_FIELD_TILLAGE_INCREASE]: 'alarming-data.checks.yoy.field-tillage-increase',
    [ALARMING_DATA_CHECK_NAMES.YOY_FIELDS_AREA_CHANGES]: 'alarming-data.checks.yoy.fields-area-changes',
    [ALARMING_DATA_CHECK_NAMES.YOY_UNLIKELY_COVER_CROP_FIELD_LEVEL]: 'alarming-data.checks.yoy.unlikely-cover-crop-field-level',
    [ALARMING_DATA_CHECK_NAMES.YOY_FIELD_TILLAGE_DISCREPANCY]: 'alarming-data.checks.yoy.tillage-strategy-discrepancy',
    [ALARMING_DATA_CHECK_NAMES.YOY_ORGANIC_FERTILIZERS_STRATEGY]: 'alarming-data.checks.yoy.organic-fertilizers-strategy',
    [ALARMING_DATA_CHECK_NAMES.YOY_HISTORICAL_FIELDS]: 'alarming-data.checks.yoy.historical-fields',
    [ALARMING_DATA_CHECK_NAMES.YOY_UNLIKELY_COVER_CROP_FIELD_LEVEL_V3]: 'alarming-data.checks.yoy.unlikely-cover-crop-field-level',
    [ALARMING_DATA_CHECK_NAMES.YOY_UNLIKELY_COVER_CROP_FIELD_LEVEL_V3_COVER_CROP_DURATION_TOO_SHORT]: 'alarming-data.checks.yoy.unlikely-cover-crop-field-level-cover-crop-duration-too-short',
    [ALARMING_DATA_CHECK_NAMES.YOY_UNLIKELY_COVER_CROP_FIELD_LEVEL_V3_COVER_CROP_SEEDING_BEFORE_PREVIOUS_HARVEST]: 'alarming-data.checks.yoy.unlikely-cover-crop-field-level-cover-crop-seeding-before-previous-harvest',
    [ALARMING_DATA_CHECK_NAMES.YOY_UNLIKELY_COVER_CROP_FIELD_LEVEL_V3_COVER_CROP_DESTRUCTION_AFTER_CROP_SEEDING]: 'alarming-data.checks.yoy.unlikely-cover-crop-field-level-cover-crop-destruction-after-crop-seeding',
    [ALARMING_DATA_CHECK_NAMES.YOY_UNLIKELY_COVER_CROP_FIELD_LEVEL_V3_TIME_BETWEEN_PREVIOUS_HARVEST_AND_SEEDING_TOO_LONG]: 'alarming-data.checks.yoy.unlikely-cover-crop-field-level-time-between-previous-harvest-and-seeding-too-long',
    [ALARMING_DATA_CHECK_NAMES.YOY_UNLIKELY_COVER_CROP_FIELD_LEVEL_V3_RAPESEED_MISSING_COVER_CROP]: 'alarming-data.checks.yoy.unlikely-cover-crop-field-level-rapeseed-missing-cover-crop',
    [ALARMING_DATA_CHECK_NAMES.YOY_UNLIKELY_COVER_CROP_FIELD_LEVEL_V3_RAPESEED_PREVIOUS_HARVEST_NOT_EQUAL_COVER_CROP_SEEDING]: 'alarming-data.checks.yoy.unlikely-cover-crop-field-level-rapeseed-previous-harvest-not-equal-cover-crop-seeding',
    // ----- BENCHMARK ----
    [ALARMING_DATA_CHECK_NAMES.BENCHMARK_ORGANIC_FERTILIZER]: 'alarming-data.checks.benchmark.organic-fertilizer',
    [ALARMING_DATA_CHECK_NAMES.BENCHMARK_TILLAGE_STRATEGY_CROP]: 'alarming-data.checks.benchmark.tillage-strategy-crop',
    [ALARMING_DATA_CHECK_NAMES.BENCHMARK_UNLIKELY_COMPANION_CROPS]: 'alarming-data.checks.benchmark.unlikely-companion-crops',
    [ALARMING_DATA_CHECK_NAMES.BENCHMARK_YIELD_BOUNDARIES]: 'alarming-data.checks.benchmark.yield-boundaries',
    [ALARMING_DATA_CHECK_NAMES.BENCHMARK_YIELD_BOUNDARIES_ORG]: 'alarming-data.checks.benchmark.yield-boundaries-org',
    [ALARMING_DATA_CHECK_NAMES.BENCHMARK_YIELD_BOUNDARIES_NON_ORG]: 'alarming-data.checks.benchmark.yield-boundaries-non-org',
    [ALARMING_DATA_CHECK_NAMES.BENCHMARK_MINERAL_FERTILIZER]: 'alarming-data.checks.benchmark.mineral-fertilizer',
    [ALARMING_DATA_CHECK_NAMES.BENCHMARK_OPERATIONS_SUCCESSIONS_PLOUGH]: 'alarming-data.checks.benchmark.operations-successions-plough',
    [ALARMING_DATA_CHECK_NAMES.BENCHMARK_OPERATIONS_SUCCESSIONS_HARVEST]: 'alarming-data.checks.benchmark.operations-successions-harvest',
    [ALARMING_DATA_CHECK_NAMES.BENCHMARK_OPERATIONS_SUCCESSIONS_SEEDING_TILLAGE]: 'alarming-data.checks.benchmark.operations-successions-seeding-tillage',
    [ALARMING_DATA_CHECK_NAMES.BENCHMARK_OPERATIONS_SUCCESSIONS_SAME_MACHINERY]: 'alarming-data.checks.benchmark.operations-successions-same-machinery',
    [ALARMING_DATA_CHECK_NAMES.BENCHMARK_OPERATIONS_SUCCESSIONS_SEEDING_SOLID_MANURE]: 'alarming-data.checks.benchmark.operations-successions-seeding-solid-manure',
    [ALARMING_DATA_CHECK_NAMES.BENCHMARK_UNUSUALY_DOUBLE_CROPS]: 'alarming-data.checks.benchmark.unusualy-double-crops',
    [ALARMING_DATA_CHECK_NAMES.BENCHMARK_UNUSUALY_DOUBLE_CROPS_V3]: 'alarming-data.checks.benchmark.unusualy-double-crops',
    [ALARMING_DATA_CHECK_NAMES.BENCHMARK_AGROFORESTRY_TREES_PER_HA]: 'alarming-data.checks.benchmark.agroforestry-trees-per-ha',
};
export const ALARMING_DATA_VALUES_SLUGS = {
    FIELD_DELETED: 'alarming-data.values.field-deleted',
    JOURNEY: 'alarming-data.years.journey',
    NA: 'alarming-data.values.na',
    NA_CONVENTIONAL: 'alarming-data.values.na-conventional',
    NO_TREES: 'alarming-data.values.no-trees',
    PERMANENT: 'alarming-data.values.permanent',
    NON_PERMANENT: 'alarming-data.values.non-permanent',
    MISSING_FIELD: 'alarming-data.values.missing-field',
    COVER_CROP_DURATION_TOO_SHORT: 'alarming-data.values.cover-crop-duration-too-short',
    COVER_CROP_SEEDING_BEFORE_PREVIOUS_HARVEST: 'alarming-data.values.cover-crop-seeding-before-previous-harvest',
    COVER_CROP_DESTRUCTION_AFTER_CROP_SEEDING: 'alarming-data.values.cover-crop-destruction-after-crop-seeding',
    TIME_BETWEEN_PREVIOUS_HARVEST_AND_SEEDING_TOO_LONG: 'alarming-data.values.time-between-previous-harvest-and-seeding-too-long',
    RAPESEED_MISSING_COVER_CROP: 'alarming-data.values.rapeseed-missing-cover-crop',
    RAPESEED_PREVIOUS_HARVEST_NOT_EQUAL_COVER_CROP_SEEDING: 'alarming-data.values.rapeseed-previous-harvest-not-equal-cover-crop-seeding',
    WINTER_CROPS: 'alarming-data.values.winter-crops',
    SPRING_CROPS: 'alarming-data.values.spring-crops',
};
export const UNITS = {
    HA: 'HA',
    L: 'L',
    L_HA: 'L_HA',
    KG: 'KG',
    T_HA: 'T_HA',
    TREE_HA: 'TREE_HA',
    KG_HA: 'KG_HA',
    CM: 'CM',
    PERCENT: 'PERCENT',
    PERCENT_N: 'PERCENT_N',
    N_MIN_HA: 'N_MIN_HA',
};
export const UNITS_SLUGS = {
    [UNITS.HA]: 'units.ha',
    [UNITS.L]: 'units.l',
    [UNITS.L_HA]: 'units.l-ha',
    [UNITS.KG]: 'units.kg',
    [UNITS.T_HA]: 'units.t-ha',
    [UNITS.TREE_HA]: 'units.tree-ha',
    [UNITS.KG_HA]: 'units.kg-ha',
    [UNITS.CM]: 'units.cm',
    [UNITS.PERCENT]: '%',
    [UNITS.PERCENT_N]: '% N',
    [UNITS.N_MIN_HA]: 'N min/ha',
};
